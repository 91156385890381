import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { FormDataService }            from '../data/formData.service';
import { NavbarComponent }    from '../navbar/navbar.component';
import { Router }              from '@angular/router';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  
  
})
export class AdminComponent implements OnInit {
  test:boolean=true;
 title = 'Multi-Step Wizard';
    @Input() formData;
    
  constructor(private formDataService: FormDataService,private router: Router) {
    console.log(router.url)
    // alert(this.router)
   }

  ngOnInit() {
        this.formData = this.formDataService.getFormData();
        console.log(this.title + ' loaded!');
    }
    home(){
      console.log("home",this.router.url);
      if(this.router.url !='/personal'){
        // alert("personal")
      this.router.navigate(['/personal']);
      
    }
    else{
      window.location.href ="https://www.krowdbox.com/";
    }
    }
    home_main(){
      this.router.navigate(['https://www.krowdbox.com/'])
    }
}
