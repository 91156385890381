import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';


import { PersonalComponent }  from '../personal/personal.component';
import { WorkComponent }      from '../work/work.component';
import { AddressComponent }   from '../address/address.component';
import { ResultComponent }    from '../result/result.component';
import { DetailsComponent } from '../details/details.component';

import { FormDataService }    from '../data/formData.service';
import { WorkflowService }    from '../workflow/workflow.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { BrowserModule } from '@angular/platform-browser'
@NgModule({
  imports: [
    CommonModule,   
    AdminRoutingModule,
     FormsModule,
     ReactiveFormsModule ,
     GooglePlaceModule,
  ],
  
  declarations: [PersonalComponent, WorkComponent, AddressComponent, ResultComponent, DetailsComponent ],
   providers:    [{ provide: FormDataService, useClass: FormDataService },
                   { provide: WorkflowService, useClass: WorkflowService },
                  
                   ],
exports: [CommonModule],
})
export class AdminModule { }
