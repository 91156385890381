import { Component, OnInit }   from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras }              from '@angular/router';

import { FormDataService }     from '../data/formData.service';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';

@Component ({
    selector:     'mt-wizard-work'
    ,templateUrl: './work.component.html'
})

export class WorkComponent implements OnInit {
  
    workType: string;
    form: any;
    cellphone_otp:any;
    totaldata:any={};
    data:any={};
    values:any={};
    check_otp_verify:boolean=false;
    cellphone_otp2:any;
    passing_data:any;
    constructor(private route: ActivatedRoute,private router: Router, private formDataService: FormDataService,public apiService : ApiService,private httpClient: HttpClient) {

     // this.router.routerState.root.queryParams.subscribe(params => {
     //        this.passing_data = JSON.parse(params["data"]);
     //        console.log("data :",params["data"])

     //    });
     
    }

    ngOnInit() {
        this.workType = this.formDataService.getWork();
        console.log('Work feature loaded!');
    }

    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
        
        this.formDataService.setWork(this.workType);
        return true;
    }

    

    goToNext(form: any) {
        if (this.save(form)) {
                console.log(localStorage.getItem("data")); 
            this.passing_data=JSON.parse(localStorage.getItem("data"));
          this.totaldata=  this.passing_data[0];
              console.log("kesesd",this.totaldata.firstname)
            var original_otp = localStorage.getItem('original_cell');
            this.values['firstname'] = this.totaldata.firstname; 
            this.values['surname'] = this.totaldata.surname;
            this.values['email'] = this.totaldata.email;
            this.values['cellphone'] = this.totaldata.cellphone;
            this.values['cell_otp'] = original_otp;
                console.log("phone otp",original_otp)
            console.log(original_otp==this.data.cellphone_otp)

            if(original_otp==this.data.cellphone_otp){

                    this.apiService.getData_2("https://app.krowdbox.com/api/insertfailedreg",this.values).subscribe((res)=>{
                        console.log(res);   
                    });

                console.log("this.passing_data",this.passing_data['0'])
                this.apiService.getData_2("https://app.krowdbox.com/api/insertCustomer",this.passing_data['0']).subscribe((res)=>{   
                     console.log(res)
                });
                this.router.navigate(['/address']);
            }
            else{
                console.log("failed")
               this.check_otp_verify=true;
            }
        }
    }


Resend(){
    let phone=localStorage.getItem("cellphone");

    localStorage.removeItem("original_cell");

    this.cellphone_otp2= Math.floor(1000 + Math.random() * 9000)  
        localStorage.setItem("original_cell",this.cellphone_otp2);
    this.apiService.getData("https://platform.clickatell.com/messages/http/send?apiKey=LQQrj8_0RY6y9XljTlY_TQ==&to=+"+'+27'+phone+"+&content=Krowdbox+:+Your+OTP+is+"+this.cellphone_otp2).subscribe((res)=>{
    console.log("api",res) 
    alert("OTP sent successfully");
    });

}


onKeyPress(){
  console.log("onKeyPress")
  this.check_otp_verify=false;
}

}