import { NgModule }           from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule }      from '@angular/platform-browser';
import { FormsModule ,ReactiveFormsModule}        from '@angular/forms';

/* App Root */
import { AppComponent }       from './app.component';

import { AppRoutingModule }   from './app-routing.module';


import { HttpClientModule } from '@angular/common/http';
import { ApiService } from './api.service';

import { AdminModule } from './admin/admin.module';
import { CreateTargetModule } from './create-target/create-target.module';


import { CreateTargetComponent }  from './create-target/create-target.component';
import { HeaderComponent } from './header/header.component';
// import { CreateGoalComponent } from './create-goal/create-goal.component';
import {MatProgressBarModule,MatTableModule,MatExpansionModule,MatFormFieldModule,MatInputModule,MatCardModule,MatDialogModule,MatSelectModule,MatDatepickerModule} from '@angular/material';


import { LoginComponent } from './login/login.component';
import { NavbarComponent }    from './navbar/navbar.component';
import {  trigger, state, style, animate, transition} from '@angular/animations';
import { BankComponent } from './bank/bank.component';
import { VerifiedBankComponent } from './verified-bank/verified-bank.component';
import { CopyLinkComponent } from './copy-link/copy-link.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ForgetComponent } from './forget/forget.component';
import { KpisComponent } from './kpis/kpis.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

// import { ReceivePaymentComponent } from './receive-payment/receive-payment.component';






@NgModule({
    imports:      [ BrowserAnimationsModule,
                    BrowserModule,
    				        HttpClientModule, 
                    FormsModule,
                    AppRoutingModule,
                    AdminModule,
                    ReactiveFormsModule,
                    CreateTargetModule,
                     MatExpansionModule,
                     MatFormFieldModule,
                     MatCardModule,
                     MatInputModule,
                     MatDialogModule,
                     MatSelectModule,
                     MatDatepickerModule,
                     MatTableModule,NgCircleProgressModule,MatProgressBarModule,NgbModule

                     

                  ],
    providers:    [
                   ApiService,MatDatepickerModule,NgCircleProgressModule
                   ],

    declarations: [ ForgetComponent,AppComponent,CreateTargetComponent, HeaderComponent/*, CreateGoalComponent,*/,LoginComponent,NavbarComponent, CopyLinkComponent, KpisComponent, ResetPasswordComponent],
    bootstrap:    [ AppComponent ],
    exports: [
              MatExpansionModule,
              MatFormFieldModule,
              MatInputModule,
              MatCardModule,
              CreateTargetComponent,
              MatSelectModule,MatDatepickerModule,MatTableModule,
              NgCircleProgressModule,MatProgressBarModule,NgbModule
            ],
    entryComponents: [/*ReviewGoalComponent,*/]

})

export class AppModule {}