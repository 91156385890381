import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras,NavigationEnd }from '@angular/router'; 
import { ApiService } from '../api.service';
import {FormControl, Validators, FormGroup} from '@angular/forms';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  data:any={};
  password_check: boolean;
  r_id: any;
  heroForm: FormGroup;
  constructor(private router: Router,public apiService:ApiService) { }

  ngOnInit() {
    this.heroForm = new FormGroup({
      'password': new FormControl(this.data.password,[
        Validators.required,
        Validators.pattern('(?=.*\d)(?=.*[a-z]).{6,}'),
       
      ]),
      'password2': new FormControl(this.data.password2,[
        Validators.required,
        Validators.pattern('(?=.*\d)(?=.*[a-z]).{6,}'),
       
      ]),    
    });
    console.log("sesssion data",sessionStorage.getItem("rr_id"))
    this.r_id = sessionStorage.getItem("rr_id");
  }

  reset(){
    this.data.r_id = this.r_id;
    console.log("this data", this.data)
    if(this.data.password == this.data.password2){
      console.log("okay equal")
      this.password_check = false;

      this.apiService.register("https://app.krowdbox.com/api/resetPassword",this.data).subscribe((res)=>{

      if(res == 'Password changed'){
        
        this.router.navigate(['/maindashboard']); 
      }
      else{
        alert("Something went wrong")
      }

    })
    }
    else{
      this.password_check = true;
      console.log("not equal")
    }
    
  }

}
