import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ApiService } from '../api.service';
import { Router } from '@angular/router';
declare var jquery:any;
declare var $ :any;
@Component({
  selector: 'app-sendgift',
  templateUrl: './sendgift.component.html',
  styleUrls: ['./sendgift.component.css']
})
export class SendgiftComponent implements OnInit {
  City: any = ['Baby shower', 'Birthday (adult) ', 'Birthday (kids under 10) ', 'Birthday (tween and teens) ', 'House-warming ', 'Fund raising ', 'Wedding', 'Other']
  showDialog:boolean=false;
  oldurl:any;
  myForm: FormGroup;
 form = new FormGroup({
         recipient_name: new FormControl('', Validators.required),
         recipient_email: new FormControl('', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
         amount: new FormControl('', Validators.required),
         cellphone: new FormControl('', [Validators.required,Validators.required,Validators.pattern("[0-9]{10}")]),
         message: new FormControl('', Validators.required)
         // own_contribution_percent: new FormControl('', Validators.required),
  });

 
  data: any={};
  amount: number;
  user_data: any;
  r_id: any;
  formData: any;
  recipient_name: any;
  recipient_email: any;
  cellphone: any;
  message: any;
  addedAmount: number;
  msgArray: any;
  cityName: any;
  category: any;
  categorydata: any;
  cat: boolean;
  recipient_name_array: any;
  title: string;
  data1: any={};
 
  constructor(public apiService:ApiService,private router: Router) { }

  ngOnInit() {
  }
  review(){
   if(this.category != undefined){
    this.title = 'krowdbox';
    // this.data = 'krowdbox';
    this.formData = this.form.value;
    console.log(this.formData.recipient_email)
    if(this.formData.recipient_email){

    }
  
    this.user_data = JSON.parse(localStorage.getItem('user_data'));
    console.log(this.user_data[0].id);
    this.r_id = this.user_data[0].id;
    this.recipient_name = this.formData.recipient_name;
    this.recipient_name_array = this.recipient_name.split(" ");
    console.log(this.recipient_name);
    if(this.formData.recipient_email !=undefined){
      this.recipient_email = this.formData.recipient_email;
    }
    else{
      this.recipient_email = '';
    }
    
    
    this.cellphone = this.formData.cellphone;
    
    console.log(this.formData.cellphone)
    this.amount = this.formData.amount;
    this.addedAmount = this.amount;
    this.categorydata = this.category;
    if(this.categorydata != ""){
      this.cat = true;
    }
    else{
      this.cat = false;
    }

    if(this.formData.message != undefined){
      this.message = this.formData.message;
     this.msgArray = this.message.split(" ");
    }
    else{
      this.msgArray = '';
    }
   
    console.log('message**',this.message)
    console.log(this.amount)
    if(this.amount > 30000){
      alert("You have exceeded the allowed limit")
    }
   else{
      console.log(this.r_id);
      this.data['r_id'] = this.r_id;
     
      this.data['amount'] = this.addedAmount;
      console.log("dikkat kya hai",this.data);
      this.apiService.post_data("https://app.krowdbox.com/api/checkLimit",this.data).subscribe(res=>{
        console.log(res);
        if(res == "Limit reached"){
          alert("You have exceeded your monthly limit");
        }
        else{
          this.showDialog = true;
          
        }
      })
     
    }
   }
    else{
      alert("Please select gift category")
    }
    

    
  }

  save(){
     this.data1['r_id'] = this.r_id;
     this.data1['recipient_name'] = this.recipient_name_array;
      this.data1['recipient_email'] = this.recipient_email;
     this.data1['recipient_number'] = this.cellphone;
     this.data1['amount'] = this.amount;
    //  this.data1['category'] = this.categorydata; 
     this.data1['message'] = this.msgArray;
     this.data1['status'] = 'Pending';
     this.data1['category'] = this.category;
    this.apiService.post_data("https://app.krowdbox.com/api/giftAsDraft",this.data1).subscribe(res=>{
      if(res == "Gift saved as draft"){
        console.log(res);
        alert("Gift saved as draft");
        this.showDialog = false;
        this.form.reset();
        this.categorydata = '';
        this.category='';
        this.router.navigateByUrl('/maindashboard');
      }
    })
  }
  Delete(){
    this.showDialog = false;
    this.form.reset();
    
  }
  selectChangeHandler(event: any){
    this.category = event.target.value; 
    console.log(event.target.value);
  }
}
