import { Component, OnInit, OnDestroy } from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ReviewGoalComponent } from '../review-goal/review-goal.component';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';
import {MatSelectModule} from '@angular/material/select';
import { FormGroup , FormControl ,Validators, NgForm,FormsModule} from '@angular/forms';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras }              from '@angular/router'; 
import { CreateTargetComponent }  from '../create-target/create-target.component';
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.css']
})
export class BankComponent implements OnInit,OnDestroy {
  panelOpenState = false;
 data:any;
 user:any={};
 firstname:any;
 lastname:any;
 identity_number:any;
 user_id:any;
 account_type:any;
 // branch:any;
 request_id:any;
 checking_id:boolean=false;
 isDesiabled:boolean=true;
 insert:boolean=false;
 result:any;
 suppose:any={};
 branch_code:any;
public value:string;
pending:boolean=false;
  form = new FormGroup({
  
    account_type: new FormControl('', Validators.required),
    branch_code: new FormControl('', Validators.required),
    account_number: new FormControl('', Validators.required),
    account_name: new FormControl('', Validators.required),
});

  constructor(private router: Router,public dialog: MatDialog,public apiService:ApiService,private route: ActivatedRoute) {

    
   console.log(JSON.parse(localStorage.getItem("user_data")))
  if(JSON.parse(localStorage.getItem("user_data"))){
  let value=JSON.parse(localStorage.getItem("user_data"));
  console.log("valuenew",value)
 // console.log("data_storage",JSON.parse(localStorage.getItem("user_data")))
  let value2=value['0'];
  console.log("value2",value2)
  console.log("data",value2.id)
  
  this.user_id=value2.id;
  console.log("user_id",this.user_id)
  // this.check_account();
  }
  else{
    console.log("empty")
  }
   }

  ngOnInit() {

    this.value = this.route.snapshot.queryParams.value;
    // alert(this.value)
    console.log("value",this.value);
    setTimeout(function(){ 

      //alert("Hello"); 
      $(".mat-expansion-panel-body").css("padding","0 1rem 0 0");

    }, 100);
   
  
  }

  ngOnDestroy(){
    console.log("ngOnDestroy called");
   }


  selectChangeHandler(event: any){

     this.user['bank_name'] = event.target.value;
    if(this.user['bank_name'] == "ABSA"){
      this.branch_code="632005";

    }
    else if(this.user['bank_name']== "African Bank"){
      this.branch_code="430000";
    }
    else if(this.user['bank_name'] == "Capitec Bank"){
      this.branch_code="470010";

    }
    else if(this.user['bank_name']== "Discovery Bank"){
      this.branch_code="679000";
    }

    else if(this.user['bank_name'] == "First National Bank"){
      this.branch_code="250655";
    }

    else if(this.user['bank_name'] == "Investec Bank"){
      this.branch_code="580105";
    }

    else if(this.user['bank_name'] == "Nedbank"){
      this.branch_code="198765";
    }
    else if(this.user['bank_name'] == "Sasfin Bank"){
      this.branch_code="683000";
    }
    
    else if(this.user['bank_name'] == "Standard Bank"){
      this.branch_code="051001";
    }
    else{
      this.branch_code="";
    }
    console.log("bank name",this.user['bank_name'])
    // this.user['bank_name'] = event.target.value;
  }

  selectChangeHandler1(event: any){
    this.user['account_type'] = event.target.value;
    this.account_type = this.user['account_type'];
    console.log("account_type",this.user['account_type'])
  }
  save(){

    console.log("user" ,this.user)
    console.log("branch_code" ,this.branch_code)


    this.user.r_id=this.user_id;
    console.log(this.user.account_name)
    console.log(this.user.account_number) 
    console.log("r_id",this.user.r_id)
    
    console.log("user data",this.user)
    console.log("tayyab")
    console.log("user",this.user)

 
    if(this.user['bank_name'] !=undefined && this.user['account_type'] !=undefined && this.user['account_number'] != undefined && this.user['account_name'] !=undefined){
      this.insert=false;
      this.checking_id=true;

      this.router.navigate(['/bank'],{ queryParams:  {"value":"change"} , skipLocationChange: false}); 

        this.apiService.register("https://app.krowdbox.com/api/getUserData",this.user).subscribe((res)=>{
      console.log("res",res) 
      this.result=res
      console.log(res[0])
      console.log(res[0].firstname)
            this.suppose=res
            console.log("here")
            console.log("length",this.suppose.length);
    // if(this.result.length !=0){

        this.identity_number = res[0].africanID;
        var str=res[0].firstname;
        this.firstname = str.substring(0, 2);
        this.lastname = res[0].surname;
        console.log("branch_code" ,this.branch_code)
        console.log("account_type",this.user['account_type'])


        

         this.apiService.getData("https://app.krowdbox.com/prod-csr-generation/bankrequest.php?action=get_bank_request&identity_number="+this.identity_number+"&initials="+this.firstname+"&last_name="+this.lastname+"&account_number="+this.user.account_number+"&branch_code="+this.branch_code+"&account_type="+ this.account_type).subscribe((res)=>{




                    setTimeout(() => {

            if(res){

            this.request_id = res['id']  
            this.apiService.getData("https://app.krowdbox.com/prod-csr-generation/bankresponse.php?action=get_response&request_id="+this.request_id).subscribe((res)=>{

             console.log(res) 
             console.log('response',res['response']) 
             var response=res['response'];
             var result_body=response['body'];
             var response1=result_body['response'];
             // var result_status=response1['result_status'];
             if(response1['result_status'] == "complete" && response1['identity_match'] == "Y" && response1['account_found'] == "Y"){

             
              this.user.account_holder_name=this.user['account_name'];
              this.user.account_no=this.user['account_number'];
              this.user.branch_code=this.branch_code;
               console.log("user",this.user)
                    this.apiService.register("https://app.krowdbox.com/api/addBank",this.user).subscribe((res)=>{

                      console.log("response",res)

                    })
                    console.log("come from",this.value)
                      if(this.value=="verified-bank/bank"){
                        console.log("come from",this.value)
                        this.router.navigate(['/bank'],{ queryParams:  {"value":"unchange"} , skipLocationChange: false});
                        this.router.navigate(['/verified-bank']);
                      }
                      else if(this.value=="create-link/verified-bank"){
                        console.log("come from",this.value)
                        this.router.navigate(['/bank'],{ queryParams:  {"value":"unchange"} , skipLocationChange: false});
                        this.router.navigate(['/create-link']);
                      } 
                      
             }
             else if(response1['result_status'] == "internal_error"){
              this.checking_id=false;
              this.router.navigate(['/bank'],{ queryParams:  {"value":"unchange"} , skipLocationChange: false});
              alert("Account not found")
             }
             else{
               this.pending=true;
               this.checking_id=false;
               this.router.navigate(['/bank'],{ queryParams:  {"value":"unchange"} , skipLocationChange: false});
             }

            

              });
        }
       },6000);

                   
         console.log("tayyab")
       

         });



      // }
      // else{
      //   console.log("data not found")
      // }
   



    // this.router.navigate(['/create-goal']);
 
  
  this.user['goal_name'] ="";
  this.user['target_amount'] ="";
  this.user['target_date'] ="";
  this.user['own_contribution_percent'] ="";
  this.user['own_contribution'] ="";  


});

    }
    else{
    this.insert=true;
  }


  
  }
  

}
