import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras ,Routes, RouterModule} from '@angular/router'; 
import {MatMenuModule} from '@angular/material/menu';
declare var jquery:any;
declare var $ :any;






@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit,OnDestroy {
  data1:any;
  user_firstname:any;
  check_component="/verified-bank";
  top_header: string;
  rout: string;
  view_logout_condition:boolean=false;

  constructor(private router: Router,) { 

    
 console.log(localStorage.getItem("user_data"));

 setInterval(function(){ 

    
  if(router.url=='/share-link'){
    this.top_header="share goal";
    $(".btn").removeClass('is_active');
    $("#creagoal").removeClass('is_active');
   
    $("#shagoal").addClass('is_active');
    $(".main").addClass('is_active');
  
  }else if(router.url== "/bank?value=create-link%2Fverified-bank" || router.url==  "/bank?value=verified-bank%2Fbank" || router.url==  "/verified-bank"){
    $(".btn").removeClass('is_active');

    $("#bank").attr("routerLinkActive","is_active");
    
    $("#bank").addClass('is_active');
    $(".main").addClass('is_active');
    
  } 
  else if(router.url=='/dashboard' || router.url=='/maindashboard'){
 
    $(".btn").removeClass('is_active');
    $(".main").addClass('is_active');
    $(".main1").addClass('is_active');
    
  }
  else if(router.url=='/create-link'){
    
    $(".btn").removeClass('is_active');
    $("#creagoal").addClass('is_active');
    $(".main").addClass('is_active');
  }
  else if(router.url=='/receive-payment'){
    $(".btn").removeClass('is_active');
    $("#receivepay").addClass('is_active');
    $(".main").addClass('is_active');
  }
  else if(router.url=='/settings'){
    
    $(".btn").removeClass('is_active');
    $("#settings").addClass('is_active');
    $(".main").addClass('is_active');
  }
  else if(router.url=='/sendgift'){
    $(".btn").removeClass('is_active');
    $("#sendgift").addClass('is_active');
    $(".main").addClass('is_active');
  }
 
}, 50);

  }


ngOnDestroy(){
  console.log("OnDestroy called header")
}

ngOnInit() { 
   

var header = document.getElementById("myTopnav");
var btns = header.getElementsByClassName("btn");
for (var i = 0; i < btns.length; i++) {
  btns[i].addEventListener("click", function() {
  var current = document.getElementsByClassName("is_active");
  
  current[0].className = current[0].className.replace(" is_active", "");
  this.className += " is_active";

  });

}
  
console.log(JSON.parse(localStorage.getItem("user_data")))
  if(JSON.parse(localStorage.getItem("user_data"))){
  let value=JSON.parse(localStorage.getItem("user_data"));
  console.log("valuenew",value)
 // console.log("data_storage",JSON.parse(localStorage.getItem("user_data")))
  let value2=value['0'];
  console.log("value2",value2)
  console.log("data",value2.id)
  
  // this.user_id=value2.id;
  this.user_firstname=value2['firstname'];
  console.log("user_firstname",this.user_firstname)
  // this.check_account();
  }
  else{
    console.log("empty")
  }
   
  }

myFunction() {
  var x = document.getElementById("myTopnav");
  if (x.className === "topnav") {
    x.className += " responsive";
  } else {
    x.className = "topnav";
  }
}


logout(){
  console.log("logout")
  localStorage.removeItem("user_data");
  localStorage.removeItem("user_target")
  
  sessionStorage.removeItem("user_logged")
  this.router.navigate(['/']);

}



dashboard(){
  $(".topnav").removeClass("responsive");
}



Share(){
  // this.router.navigate(['/share-goal']);
  $(".topnav").removeClass("responsive");
}


Partner(){
  $(".topnav").removeClass("responsive");
}


create(){
  console.log("create-goal")
// this.router.navigate(['/create-goal']);
$(".topnav").removeClass("responsive");
}

verified(){
 console.log("verified-bank")
  // this.router.navigate(['/verified-bank']);

  $(".topnav").removeClass("responsive");
}

settings(){
  
  $(".topnav").removeClass("responsive");
}
sendgift(){
  console.log("sfs")
  $(".topnav").removeClass("responsive");
}
receivepay(){
  $(".topnav").removeClass("responsive");
}

button_logout(){
  if(this.view_logout_condition==true)
  {
    this.view_logout_condition=false;
  }
  else{
  this.view_logout_condition=true;
}
}
}
