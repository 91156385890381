import { Component, OnInit, Input,ViewChild }   from '@angular/core';

import { FormData }                   from '../data/formData.model';

import { FormDataService }            from '../data/formData.service';
import { Router ,NavigationExtras}              from '@angular/router';
import { ApiService } from '../api.service';
import { LoginComponent } from '../login/login.component';

  // @ViewChild("placesRef") placesRef : GooglePlaceDirective;

@Component ({
    selector:     'mt-wizard-result'
    ,templateUrl: './result.component.html'
})

export class ResultComponent implements OnInit {
    @Input() formData: FormData;
    isFormValid: boolean = false;
    formattedaddress:any='';
    landmark:any='';
    data:any={};
    passing_data:any;
    options={
    // types: [],
    componentRestrictions: { country: 'ZA' }
    };
    checking_id:boolean=false;
    data_store:any;
    response:any;
    data_login:any={};
    get_id:any;
    status: any;
    constructor(private router: Router,public apiService:ApiService,private formDataService: FormDataService) {
    }

    ngOnInit() {
        this.formData = this.formDataService.getFormData();
        this.isFormValid = this.formDataService.isFormValid();
    }

     submit(form: any) {
      
        // this.formData = this.formDataService.resetFormData();
        this.isFormValid = false;
       console.log("data_african_id",localStorage.getItem("data_african_id"))
        this.passing_data=JSON.parse(localStorage.getItem("data"));
        console.log("data",JSON.parse(localStorage.getItem("data")))
       this.passing_data['0'].cell_otp=localStorage.getItem('original_cell');
        this.passing_data['0'].africanID=localStorage.getItem("data_african_id");
        this.passing_data['0'].status=localStorage.getItem("verification");
        if(this.formattedaddress != ''){
            this.passing_data['0'].address = this.formattedaddress;
           }
           else{
               this.passing_data['0'].address = form.value.address;
           }
       
        // this.passing_data['0'].address = "fgsdfgd";
        this.passing_data['0'].landmark = this.landmark
        console.log("this.passing_data",this.passing_data)       
        console.log("data",this.passing_data['0']);

        this.apiService.register("https://app.krowdbox.com/api/totalcustomerdata",this.passing_data['0']).subscribe((res)=>{
        console.log("res",res) 
        });

        alert('Your registration is successful. Access our platform and perform your first transaction.');
        
        this.checking_id=true;
        console.log("data_store",this.passing_data['0']);
        let login=this.passing_data['0'];
        this.data_login.email=login['email']
        this.data_login.password=login['password']
        console.log("data_login",this.data_login)
        

        setTimeout(() => {

            let data =  this.apiService.register("https://app.krowdbox.com/api/login",this.data_login).subscribe((res)=>{
            console.log("res",res, "datanew",data);
            this.response=res['0'];
            // localStorage.setItem("user_data",JSON.stringify(this.passing_data));
            this.get_id=this.response['id'];
            console.log("id_res",res['0'].status);
            this.status = res['0'].status;

            
            console.log("get_id",this.get_id);
           
            this.passing_data['0'].id=this.get_id;
            console.log("passing_data",this.passing_data);
            console.log("id",this.passing_data['0'].id);
            
            
            
             console.log("passing_data",this.passing_data);
            this.data_store = this.response;
            console.log("here")
            console.log("data_store1",this.response)
            console.log("data_store1",this.data_store);
           
            console.log("rathore")
            console.log("passing_data",this.passing_data);

            localStorage.setItem("user_data",JSON.stringify(this.passing_data));
            if(this.status == "Active"){
                this.afterSettimeout();
            }
            else{
                window.location.href='https://www.krowdbox.com'; 
            }
            
        }); 
    },3000)
}

public afterSettimeout(){
    sessionStorage.setItem("user_logged","true");  
    this.router.navigate(['/maindashboard']); 
}

public handleAddressChange(address: any) {
    this.formattedaddress=address.formatted_address;
    console.log("this.formattedaddress :-",this.formattedaddress)
    
}

public handleAddressChange1(address: any) {
    this.landmark=address.formatted_address;
    
}
}
