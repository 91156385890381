import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCardModule} from '@angular/material/card';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, FormArray, FormControl ,Validators, NgForm,FormsModule} from '@angular/forms';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras,RouterEvent, NavigationEnd  }              from '@angular/router'; 
import { CreateTargetComponent }  from '../create-target/create-target.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { DatePipe } from '@angular/common'
import * as moment from 'moment';

declare var jquery:any;
declare var $ :any;


@Component({
  selector: 'app-create-goal',
  templateUrl: './create-goal.component.html',
  styleUrls: ['./create-goal.component.css'],
  
  
})
export class CreateGoalComponent implements OnChanges, OnInit ,OnDestroy {
panelOpenState = false;
data:any;
rinki:any;

user:any={};
notification_value:any;
data1:any;
// goal:any;
showDialog:boolean=false;
add_status:boolean=false;
review_status:boolean=false;
own_contribution_percent2:boolean=false;
user_id:any;
data_res:any={};
own_contribution:any;
choose_bank:boolean=false;
user_details:any={};
user_details1:any={};
bank_id:any={};
bank_id1:any={};
own_contribution_percent1:boolean=false;
select_bank:any;
select_bank2:any;
select_bank3:any;
select_bank4:any;
select_bank5:any;
select_bank6:any;
isChecked1:boolean=false;
isChecked2:boolean=false;
isChecked3:boolean=false;
isChecked4:boolean=false;
isChecked5:boolean=false;
no_bank_account:boolean=true;
isChecked6:boolean=false;
value_select:any;
current_date:any;
validate_date:boolean=false;
validate_amount:boolean=false;
validate_duration:boolean=false;
variable_condition:boolean;
next_date:any;
currentUrl:any;
previousUrl:any;
categories:any={};
name:any;
value:any;

myForm: FormGroup;
 form = new FormGroup({
         goal: new FormControl('', Validators.required),
         target_date: new FormControl('', Validators.required),
         target_amount: new FormControl('', Validators.required),
         // own_contribution_percent: new FormControl('', Validators.required),
  });
  
 
  emails = [{  value: "25",name:"25" }, {  value: "50",name:"50" }, { value: "75" ,name:"75" }]
  notification_array:any=[{name:25,value:25},{name:50,value:50},{name:75,value:75}]
  target_date: string;
  changeLog: any;
  category: any;
  constructor(private fb: FormBuilder,public datepipe: DatePipe,private router: Router,public dialog: MatDialog,public apiService:ApiService) {
  console.log("notification_array",this.notification_array)
this.target_date=this.datepipe.transform(new Date(), 'MM/dd/yyy');
console.log("today new Date()",this.datepipe.transform(new Date(), 'MM/dd/yyyy'))


    if(JSON.parse(localStorage.getItem("user_data"))){
        let value=JSON.parse(localStorage.getItem("user_data"));
        console.log("valuenew",value)
        let value2=value['0'];
        console.log("value2",value2)
        console.log("data",value2.id)
        this.user_id=value2['id'];
        console.log("user_id",this.user_id)
        this.check_account();
      }
      else{
        console.log("empty")
      }

      
   }
   ngOnChanges(changes: SimpleChanges) {
   
  }
  ngOnInit() { 
    this.user['target_date'] ="";
    // =this.datepipe.transform(new Date(), 'MM/dd/YYYY');
    console.log("ngOnInit called")
    this.myForm = this.fb.group({
      useremail: this.fb.array([])
    });
    
    var date = new Date();
    this.current_date =this.datepipe.transform(date, 'yyyy-MM-dd');
    console.log("date",this.current_date);

    setTimeout(function(){ 
      $(".mat-expansion-panel-body").css("padding","0 24px 16px");
    }, 100);

    this.categories=[{ value:'25',name:'first'},{ value:'50',name:'second'},{ value:'75',name:'third'}];
    console.log("categories",this.categories)

    if(JSON.parse(localStorage.getItem("user_target"))){
      console.log("data_target",JSON.parse(localStorage.getItem("user_target")))
      this.user=JSON.parse(localStorage.getItem("user_target"));
      console.log("data exists")
    }
    else {
       console.log("empty data")
    }
   console.log(JSON.parse(localStorage.getItem("user_data")))
  
   
  }
  ngOnDestroy(){
    console.log("ngOnDestroy called")
  }

onChange(email: string, isChecked: boolean) {
    const emailFormArray = <FormArray>this.myForm.controls.useremail;

    if (isChecked) {
     let value= emailFormArray.push(new FormControl(email));
     
     this.notification_value=this.myForm.value.useremail.join(",");
     console.log("value",this.notification_value);
    } else {
      let index = emailFormArray.controls.findIndex(x => x.value == email)
      console.log("index",index)
      emailFormArray.removeAt(index);
    }
  }
check_account(){

  this.user.r_id=this.user_id
  this.apiService.register("https://app.krowdbox.com/api/getBankDetails",this.user).subscribe((res)=>{
    console.log("check_account",res)
    this.data=res;
    console.log("data",this.data);

    if(this.data == "No data found"){
      console.log("data No data found");
      this.no_bank_account=true;
    }
    else{
      console.log("data");
      this.no_bank_account=false;
    }
        
  });

}



selectChangeHandler(event: any) {

  this.user['own_contribution_percent'] = event.target.value;
  console.log("own_contribution_percent",this.user['own_contribution_percent'])

  if(this.user['own_contribution_percent']!="none"){
    this.own_contribution_percent1=true;
  }
  else{
    this.own_contribution_percent1=false;

  }
}
selectChangeHandler1(event: any){
  this.category = event.target.value; 
  console.log(event.target.value);
}



	review(){
    if(this.category != undefined){
      console.log("target_date",this.target_date)
      console.log("target_date",this.target_date['year'])
      console.log("target_date",this.target_date['month'])
      console.log("target_date",this.target_date['day'])
      this.user['target_date']=this.target_date['year']+'/'+this.target_date['month']+'/'+this.target_date['day']
      console.log("user.target_date",this.user['target_date'])
      this.data_res.r_id=this.user_id;
      var d = new Date(this.current_date);
  
      console.log("user",this.user)
      var year = d.getFullYear();
      var month = d.getMonth();
      var day = d.getDate();
      let c = new Date(year + 1, month, day);
  
  
      this.current_date=moment().format("YYYY-MM-DD");
      this.user['target_date']=moment(this.user['target_date']).format("YYYY-MM-DD");
      this.next_date=moment().add(1, 'year');
      this.next_date=moment(this.next_date['_d']).format("YYYY-MM-DD");
  
  
      console.log("current_date",this.current_date)
      console.log("target_date",this.user['target_date']);
      console.log("next_date",this.next_date);
      console.log(this.user['target_date'] <= this.next_date)
      
     if((this.user['target_date'] <= this.next_date) && (this.current_date<= this.user['target_date']) ){
  
        console.log("r_id",this.data_res.r_id)
        
        console.log("this.value_select",this.value_select)
        console.log("this.notification_value",this.notification_value)
        this.apiService.register("https://app.krowdbox.com/api/checkBankAccount",this.data_res).subscribe((res)=>{
                
        console.log("res",res)
            if(res == "Bank account does not exist"){
              console.log("if")
                this.review_status=true;
                this.showDialog = true;
            }
            else {  
              console.log("else")
                this.review_status=true;
                this.add_status=true;
                this.showDialog = true;
            }
        });
  
        console.log("user",this.user)
        this.user_details=this.user;
      
      }
  
    /* -----------------------need to uncomment-----------------------------*/
  
    console.log("variable_condition",this.variable_condition)
  
    this.variable_condition=(this.user['target_date']> this.next_date) && (this.current_date > this.user['target_date']);
  
    if(this.variable_condition==false){
      
              console.log("less and current");
              
              if(this.user['target_date'] > this.next_date){
                console.log("condition1")
                
              this.validate_duration=true;
              this.validate_date=false
            }
              if(this.current_date> this.user['target_date']){
                console.log("condition2")
                this.validate_date=true;
                // this.validate_amount=true;
                this.validate_duration=false
              }
    }
      // if(this.user['target_amount']>100000){
      //       this.validate_amount=true;
            
      //     }
    }
   else{
     alert("Please select what your QuickPay is for")
   }

    
  }
  
  input1(){
    this.validate_amount=false;

  }


  input2(){
    this.validate_date=false;

  }

  activate(){
        console.log("activated") 
        this.showDialog = false;

        console.log("value_select",localStorage.getItem("value_select"));
        if(localStorage.getItem("value_select") == null){

        this.user.r_id=this.user_id
        console.log("rinki1",this.user)
        this.apiService.register("https://app.krowdbox.com/api/getBankDetails",this.user).subscribe((res)=>{
            console.log("res",res)
            
            this.data=res;
            
            console.log("data",this.data)
        });

        console.log("select bank1",this.select_bank)
        this.choose_bank = true;

      }
      else{
        console.log("rathore");
        this.select_bank=localStorage.getItem("value_select");
        this.user_details1.bank_name=this.select_bank;
        this.user_details1.r_id=this.user_id;

        this.apiService.register("https://app.krowdbox.com/api/getbankid",this.user_details1).subscribe((res)=>{
         
          this.bank_id = res;
         
          this.user_details.bank_name=this.select_bank;
        
          this.user_details.bank_id=this.bank_id;

          this.user_details.status="Activate";
        this.user_details.category = this.category;
        // this.notification_value = this.notification_value.join(",")
       console.log("s",this.notification_value)
        //  this.user_details.notification=this.notification_value;

          
          
          console.log("rinki2",this.user_details)
        this.apiService.register("https://app.krowdbox.com/api/createGoal",this.user_details).subscribe((res)=>{
            console.log("res",res) 
            alert("QuickPay link created successfully");
            localStorage.removeItem("value_select");
            localStorage.removeItem("user_target");
            this.router.navigate(['/share-link']);
          });
       
          
        });          
        
        
       

      }

   

    
    
    console.log("id",this.user_id)
  }


  addaccount(value){
    console.log("come in",value);
    console.log("user_data",this.user);
    localStorage.setItem("user_target",JSON.stringify(this.user));

    this.add_status=true;
    console.log("add")
    this.router.navigate(['/bank'],{ queryParams:  {"value":value} , skipLocationChange: false}); 
  }

  save(){
  this.validate_date=false;
  this.validate_duration=false;
    this.user.status="Pending";
    this.user.r_id=this.user_id;
    console.log("user rinki",this.user)
      console.log("this.data_user",this.user);
      console.log("save as draft");
      console.log("user_id",this.user_id);
      this.select_bank=localStorage.getItem("value_select");
      this.user_details.bank_name=this.select_bank;
      
      this.user_details.bank_id=this.bank_id;

      this.user_details1.status="Pending";
      console.log("this.user_details",this.user_details);

      this.user_details1.r_id=this.user_id;
      this.user_details1.bank_name=this.select_bank;
      console.log("this.user_details1",this.user_details1)

      this.apiService.register("https://app.krowdbox.com/api/getbankid",this.user_details1).subscribe((res)=>{
       console.log("response",res)
        this.bank_id = res;
       
        this.user.bank_name=this.select_bank;
      
        this.user.bank_id=this.bank_id;

        this.user.status="Pending";
    
        this.user_details.notification=this.notification_value;
        this.apiService.register("https://app.krowdbox.com/api/saveAsDraft",this.user).subscribe((res)=>{
         console.log("res",res) 
         
          alert("Goal saved successfully")

          this.user['goal_name'] ="";
          this.user['target_amount'] ="";
          this.user['target_date'] ="";
          this.user['own_contribution_percent'] ="None";
          this.user['own_contribution'] ="";  
          this.showDialog = false;
          $('.chb').prop('checked', false);
          localStorage.removeItem("user_target");
          this.router.navigate(['/maindashboard']);
  });

      })

}

  Delete(){
          this.validate_date=false;this.validate_duration=false;
          console.log("delete");
          this.user['goal_name'] ="";
          this.user['target_amount'] ="";
          this.target_date ="";
          this.user['own_contribution_percent'] ="None";
          this.user['own_contribution'] =""; 
          this.showDialog = false; 
          // localStorage.removeItem("user_target");
          $('.chb').prop('checked', false);
  }
  keypress(){

  }
 

  close(){
    this.showDialog = false;
    this.validate_duration=false;
    this.validate_date=false;
    this.user['goal_name'] = "";
    this.user['target_amount'] ="";
    this.target_date ="";
          this.user['own_contribution_percent'] ="None";
          this.user['own_contribution'] =""; 
          this.showDialog = false; 
          localStorage.removeItem("user_target");
          $('.chb').prop('checked', false);
  }


  get_value(value){

 
   console.log(" Value is : ", value.target.value );
   this.value_select=value.target.value;
   localStorage.setItem("value_select",this.value_select);
  }

hide_(){
  this.validate_date=false;
  console.log("hide");
  localStorage.removeItem("user_target");
}


}
