import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute ,NavigationExtras }from '@angular/router'; 
import { ApiService } from '../api.service';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
})
export class ForgetComponent implements OnInit {
  data:any={};
  email_check: boolean=false;
  constructor(public router:Router,public apiService:ApiService) { }

  ngOnInit() {
  }

  send(){
    console.log("email",this.data.email)
    // if(this.data)
    this.apiService.register("https://app.krowdbox.com/api/forgotPassword",this.data).subscribe((res)=>{      
    console.log("response email",res);    
     console.log("send");
     if(res=="Email id doesn't exist"){
    //  alert(res);
     this.email_check=true;
    }
    else{
      alert("We have sent you an email to reset your password.");
      this.router.navigate(['/']) 
    }
    
    });
    
  }
  onKeyPress(){
	  console.log("onKeyPress")
	  this.email_check=false;
  }
}
