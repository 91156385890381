import { Component, OnInit }   from '@angular/core';
import { Router ,NavigationExtras}              from '@angular/router';

import { Personal }            from '../data/formData.model';
import { FormDataService }     from '../data/formData.service';

import { ApiService } from '../api.service';

@Component ({
    selector:     'mt-wizard-personal'
    ,templateUrl: './personal.component.html'
})

export class PersonalComponent implements OnInit {
    personal: Personal;
    form: any;
    response:any;
    // firstname: any;
    // surname: any;
    data:any={};
    email_check:boolean=true;
    data_store:any={};
    referral_code:any;
    constructor(public apiService:ApiService,private router: Router, private formDataService: FormDataService) {
    }

    ngOnInit() {
        this.personal = this.formDataService.getPersonal();
    }

    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
            
        this.formDataService.setPersonal(this.personal);
        return true;
    }

    submit(form: any) {

        
    console.log("out")
        if (this.save(form)) {
            
            this.data['referral_code']=this.referral_code;
            console.log("Rinky",this.data)
            this.apiService.register("https://app.krowdbox.com/api/register",this.data).subscribe((res)=>{
              console.log("res",res) 
              this.response=res;
                if(this.response=="email id already exist"){
                    this.email_check=false;
                }
                else { 
                   
                    this.data_store[0] = this.data;
                    localStorage.setItem("data",JSON.stringify(this.data_store));                
                    console.log("otp" ,this.response.otp)
                    localStorage.setItem('emailotp',this.response.otp);        
                    localStorage.setItem('cellphone',this.data.cellphone);        
                    // this.router.navigate(['/details'],navigationExtras);
                    this.router.navigate(['/details']);
                 }
              });

            
        }
    }

onKeyPress(){
  console.log("onKeyPress")
  this.email_check=true;
}


}
