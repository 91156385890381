import { Component, OnInit } from '@angular/core';
import { Details }            from '../data/formData.model';
import { FormDataService }     from '../data/formData.service';
import { ApiService } from '../api.service';
import { Router,ActivatedRoute ,NavigationExtras }              from '@angular/router'; 

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
details:Details;
form: any;
data:any={};
check_otp_verify:boolean=false;
original_cell_otp:any;
passing_data=[];
passing_data1:any;
data2:any={};
  constructor(public apiService:ApiService,private router: Router, private formDataService: FormDataService) {

// this.router.routerState.root.queryParams.subscribe(params => {
// 	        this.passing_data = params["data"];
// 	        console.log("data :",params["data"])

// 	    });
// console.log(" this.passing_data", this.passing_data)
   }


  ngOnInit() {
  	this.details = this.formDataService.getDetails();
        console.log('Personal feature loaded!');
  }

save(form: any): boolean {
        if (!form.valid) {
            return false;
        }
            
        this.formDataService.setDetails(this.details);
        return true;
    }


otp_verify(form: any){

	console.log("hhohjoh");
	if (this.save(form)) {
		
		var user_input =this.data.otp;
		var email_otp=localStorage.getItem('emailotp')

		if(user_input==email_otp)
		{
		  localStorage.removeItem("email_otp");

		  let phone=localStorage.getItem("cellphone");

  		console.log("data_stored",localStorage.getItem("data"))
		  console.log("phone",phone)

		  this.original_cell_otp=Math.floor(1000 + Math.random() * 9000);
		  localStorage.setItem('original_cell',this.original_cell_otp);

		  this.apiService.getData("https://platform.clickatell.com/messages/http/send?apiKey=LQQrj8_0RY6y9XljTlY_TQ==&to=+"+'+27'+phone+"+&content=Krowdbox+:+Your+OTP+is+"+this.original_cell_otp).subscribe((res)=>{
			  console.log("api",res)
			  	 
		  });



		    let navigationExtras: NavigationExtras = {
                        queryParams: {
                            data:this.passing_data
                        }
                    };
		   // this.router.navigate(['/work'],navigationExtras);
		   this.router.navigate(['/work']);
		  }
		  else{
		  	console.log("failed")
		  	console.log("check_otp_verify",this.check_otp_verify)
		  		this.check_otp_verify=true;
		  }

		  
	}

}

Resend(){
		localStorage.removeItem('emailotp')
  	 	this.passing_data1=JSON.parse(localStorage.getItem("data"));
		console.log("data :",this.passing_data1['0'].email)
		console.log("data :",this.passing_data1['0'].firstname)
	  	this.data2.email=this.passing_data1['0'].email;
	  	this.data2.firstname=this.passing_data1['0'].firstname;
	  	console.log("data2 : ",this.data2)

  		this.apiService.post_data("https://app.krowdbox.com/api/resendmail",this.data2).subscribe((res)=>{
          console.log("res",res)
          var email_otp= localStorage.setItem('emailotp',res['otp']);
          alert("OTP sent successfully");
          });

}
onKeyPress(){
  console.log("onKeyPress")
  this.check_otp_verify=false;
}
}
